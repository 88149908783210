import Vue from 'vue'
import VueRouter from 'vue-router'
import index from "@/views/index.vue"
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [{
	path: '/',
	redirect: "/index"
},
{
	path: '/index',
	component: index,
	redirect: "/index/home",
	children: [{
		path: 'home',
		component: () => import("@/views/home/index.vue"),
		meta: {
			header: true
		}
	},
	{
		path: 'about',
		component: () => import("@/views/about/index.vue"),
		meta: {
			header: true
		}
	},
	{
		path: "school_detail",
		component: () => import("@/views/home/school_detali.vue"),
	},
	{
		path: 'information',
		component: () => import("@/views/information/index.vue"),
		redirect: "/index/information/directory",
		children: [{
			path: "directory",
			component: () => import("@/views/information/directory.vue"),
			meta: {
				header: true
			},
		}, {
			path: "list",
			component: () => import("@/views/information/list.vue"),
			meta: {
				header: true
			},
		},]
	},
	{
		path: "informationdetali",
		component: () => import("@/views/information/detali.vue"),
	},
	{
		path: 'news',
		component: () => import("@/views/news/index.vue"),
		meta: {
			header: true
		},
		redirect: "/index/news/list",
		children: [{
			path: "detali",
			component: () => import("@/views/news/detali.vue"),
			meta: {
				header: true
			},
		}, {
			path: "list",
			component: () => import("@/views/news/list.vue"),
			meta: {
				header: true
			},
		}],
	},
	{
		path: 'material',
		component: () => import("@/views/material/index.vue"),
		meta: {
			header: true
		}
	},
	{
		path: 'IPindex',
		component: () => import("@/views/IPindex/index.vue"),
		meta: {
			header: true
		}
	},
	{
		path: 'IPindexAll',
		component: () => import("@/views/IPindex/all.vue"),
		// meta: {
		// 	header: true
		// }
	},
	{
		path: 'IPindexDetail',
		component: () => import("@/views/IPindex/detalis.vue"),
		// meta: {
		// 	header: true
		// }
	},
	{
		path: 'IPstorage',
		component: () => import("@/views/IPstorage/index.vue"),
		meta: {
			header: true
		},
	},
	{
		path: 'service',
		component: () => import("@/views/service/index.vue"),
		meta: {
			header: true
		},
	},
	{
		path: 'servicedetali',
		component: () => import("@/views/service/detali.vue"),
	},
	{
		path: 'settle',
		component: () => import("@/views/settle/index.vue"),
		meta: {
			header: true
		},
	},
	{
		path: 'service_add',
		component: () => import("@/views/settle/service_add.vue"),
		meta: {
			header: true
		},
	},
	{
		path: 'apply_add',
		component: () => import("@/views/settle/sq_add.vue"),
		meta: {
			header: true
		},
	},
	{
		path: 'ip_xuqiu',
		component: () => import("@/views/settle/ip_xuqiu"),
		meta: {
			header: true
		},
	},
	{
		path: 'ip_add',
		component: () => import("@/views/settle/ip_add"),
		meta: {
			header: true
		},
	},
	{
		path: 'Materialall',
		component: () => import("@/views/material/all.vue"),
	},
	{
		path: 'Materialdetalis',
		component: () => import("@/views/material/detalis.vue"),
	},

	{
		path: 'Materialdetalis',
		component: () => import("@/views/material/detalis.vue"),
	},

	{
		path: 'guide',
		component: () => import("@/views/guide/index.vue"),
		meta: {
			header: true
		},
		redirect: "/index/guide/list",
		children: [{
			path: "detali",
			component: () => import("@/views/guide/detali.vue"),
			meta: {
				header: true
			},
		}, {
			path: "list",
			component: () => import("@/views/guide/list.vue"),
			meta: {
				header: true
			},
		}],
	},
	{
		path: "EventPage",
		component: () => import("@/views/EventPage/index.vue"),
		meta: {
			header: true
		},
	},
	{
		path: 'activityDetail',
		component: () => import("@/views/EventPage/detali.vue"),
		// meta: {
		// 	header: true
		// }
	},
	{
		path: 'PinDetail',
		component: () => import("@/views/EventPage/pin_detail.vue"),
		// meta: {
		// 	header: true
		// }
	},

	]
},

]

const router = new VueRouter({
	routes,
	// mode: "history"
	scrollBehavior() {
		// return 期望滚动到哪个的位置
		return {
			x: 0,
			y: 0
		}
	}
})

export default router
