<template>
  <div class="Login">
    <!-- <div class="Login_title">登录</div>
    <div class="Login_Text">ACCOUNT LOGIN</div> -->
    <div style="transform: scale(0.7); position: relative; left: -40px">
      <img src="@/assets/login/login.png" alt="" />
    </div>
    <el-form ref="form" :model="form">
      <el-form-item>
        <el-input v-model="form.username" placeholder="请输入手机号">
          <i slot="prefix">
            <img
              style="position: relative; top: 2px; width: 18px; height: 18px"
              src="@/assets/user/user_icon2.png"
              alt=""
            />
          </i>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.password"
          placeholder="请输入密码"
          type="password"
        >
          <i slot="prefix">
            <img
              style="position: relative; top: 5px; width: 18px; height: 18px"
              src="@/assets/user/user_icon3.png"
              alt=""
            />
          </i>
        </el-input>
      </el-form-item>
      <el-form-item>
        <div class="end">
          <div>微信登录</div>
          <div @click="ForgotPass">忘记密码?</div>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="send" @click="LoginSend">登录</div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "login",
  data() {
    // var checkPhones = (rule, value, callback) => {
    //   //console.log(value, rule);
    //   if (!value) {
    //     return callback(new Error("手机号不能为空"));
    //   } else {
    //     const reg = /^1[1|3|4|5|7|8|9][0-9]\d{8}$/;
    //     //console.log(reg.test(value));
    //     if (reg.test(value)) {
    //       callback();
    //     } else {
    //       return callback(new Error("请输入正确的手机号"));
    //     }
    //   }
    // };
    return {
      //   rules: {
      // tel: [
      //   { required: true, message: "请输入手机号", trigger: "blur" },
      //   { validator: checkPhones },
      // ],
      // passwords: [
      //   {
      //     required: true,
      //     message: "请输入密码",
      //     trigger: "blur",
      //   },
      //   {
      //     min: "6",
      //     message: "密码最少6位字符",
      //   },
      // ],
      //   },
      form: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions(["Setuserinfo"]),
    LoginSend() {
      this.$refs.form.validate(async (data) => {
        if (data) {
          const userdata = await this.$api({
            c: "login",
            a: "pc_login",
            user_phone: this.form.username,
            password: this.form.password,
          });
          if (userdata.errorCode == 200) {
            this.$message({
              type: "success",
              message: userdata.description,
            });
            const data = userdata.data;
            this.Setuserinfo(data);
            this.$emit("Setlogin", false);
            /**ssod登录 */
            var pwd = data.resttoken;
            console.log("开始oss登录");
            this.ssologin(this.form.username, pwd);
          } else if (userdata.errorCode == 403) {
            this.$message.error(userdata.description);
          } else if (userdata.errorCode == 500) {
            this.$message.error(userdata.description);
          }
        }
      });
    },

    ForgotPass() {
      this.$emit("ForgotPass");
    },
    ssologin(phone, pwd) {
      var self = this;
      //登录成功后请求sso
      // let param = new URLSearchParams();
      // param.append("phone", phone);
      // param.append("project", "ipjy");
      // param.append("password", pwd);
      // param.append("c", "sso_login");
      // param.append("a", "pc_login"); 
      // console.log("参数----",param);
      // const param={c:"sso_login",a:"pc_login",phone:phone,project:'ipjy',password:pwd};
    //  var  param={c:"sso_login",a:"pc_login",phone:phone,project:'ipjy',password:pwd};
      
      self
        .$axios({
          method: "get",
          url: "https://www.jscaee.com.cn/rest/index.php?c=sso_login&a=pc_login&phone="+phone+"&project=ipjy&password="+pwd,
        })
        .then((res) => {
          // console.log(" 开始oss登录--222222--", res.data);
          if (res.data.errorCode == 200) {
            localStorage.setItem("sign", res.data.data.token);
            localStorage.setItem("uid", res.data.data.uid);
            // document.domain = 'jscaee.com.cn';
            var elem_tymh = document.createElement("iframe");
            elem_tymh.width = 0;
            elem_tymh.height = 0;
            elem_tymh.src =
              "https://www.jscaee.com.cn/portal/#/?sign=" +
              res.data.data.token +
              "&uid=" +
              res.data.data.uid;
            document.body.appendChild(elem_tymh);
            elem_tymh.onload = elem_tymh.onreadystatechange = function () {
              if (
                !this.readyState ||
                this.readyState === "loaded" ||
                this.readyState === "complete"
              ) {
                //   if (callback) {
                //     callback();
                //   }
              }
            };
            var elem_sybm = document.createElement("iframe");
            elem_sybm.width = 0;
            elem_sybm.height = 0;
            elem_sybm.src =
              "https://bqss.jscaee.com.cn/portal/#/?sign=" +
              res.data.data.token +
              "&uid=" +
              res.data.data.uid;
            document.body.appendChild(elem_sybm);
            elem_sybm.onload = elem_sybm.onreadystatechange = function () {
              if (
                !this.readyState ||
                this.readyState === "loaded" ||
                this.readyState === "complete"
              ) {
                //   if (callback) {
                //     callback();
                //   }
              }
            };
            var elem_ipjy = document.createElement("iframe");
            elem_ipjy.width = 0;
            elem_ipjy.height = 0;
            elem_ipjy.src =
              "https://ip.jscaee.com.cn/portal/#/index/home?sign=" +
              res.data.data.token +
              "&uid=" +
              res.data.data.uid;
            document.body.appendChild(elem_ipjy);
            elem_ipjy.onload = elem_ipjy.onreadystatechange = function () {
              if (
                !this.readyState ||
                this.readyState === "loaded" ||
                this.readyState === "complete"
              ) {
                //   if (callback) {
                //     callback();
                //   }
              }
            };

            var elem_ysjy = document.createElement("iframe");
            elem_ysjy.width = 0;
            elem_ysjy.height = 0;
            elem_ysjy.src =
              "https://film.jscaee.com.cn/portal/#/?sign=" +
              res.data.data.token +
              "&uid=" +
              res.data.data.uid;
            document.body.appendChild(elem_ysjy);
            elem_ysjy.onload = elem_ysjy.onreadystatechange = function () {
              if (
                !this.readyState ||
                this.readyState === "loaded" ||
                this.readyState === "complete"
              ) {
                //   if (callback) {
                //     callback();
                //   }
              }
            };
            //  self.$router.push("/");
          } else {
            self.$message("网络连接失败");
            return false;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.Login {
  padding: 0px 0px 10px;

  .Login_title {
    color: rgb(55, 69, 103);
    font-size: 20px;
  }

  .Login_Text {
    margin-top: 4px;
    color: rgb(122, 139, 166);
    font-style: italic;
    font-size: 12px;
    margin-bottom: 20px;
  }

  .end {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    color: #565656;
  }

  .send {
    height: 40px;
    padding: 4px 0;
    width: 100%;
    border-radius: 8px;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    background: rgb(0, 118, 254);
  }
}
</style>
