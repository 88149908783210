<template>
  <div class="index">
    <header
      class="header align-center"
      :class="$route.meta.header ? 'header-fixed' : 'header-fixed-aquites'"
    >
      <div
        :class="$route.meta.header ? 'header-img' : 'header-img-aquites'"
        style=""
      >
        <img src="@/assets/img/header_Logo.png" alt="" />
      </div>
      <ul
        class="menu-tab flex font-three"
        :class="$route.meta.header ? 'header-UL' : 'header-UL-aquites'"
      >
        <router-Link to="/index/home">
          <li>首页</li>
        </router-Link>
        <router-Link to="/index/about">
          <li>关于中心</li>
        </router-Link>
        <!-- <router-Link to="/index/information">
					<li>信息公示</li>
				</router-Link> -->
        <router-Link to="/index/news">
          <li>中心动态</li>
        </router-Link>
        <router-Link to="/index/guide">
          <li>业务指引</li>
        </router-Link>
        <a @click="loginVisible = true" v-if="!userinfo.token">
          <li style="font-weight: bold">登录</li>
        </a>
        <el-dropdown trigger="click">
          <div style="cursor: pointer">
            <a v-if="userinfo.token">
              <li style="font-weight: bold">{{ userinfo.nickname }}</li>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </a>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in userinfo.userinfo"
              :key="index"
            >
              <div @click="userDetail(index)">{{ item.nickname }}</div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="loginOut">退出登录</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </ul>
    </header>
    <div
      class="grow-1"
      :style="$route.meta.header ? '' : 'padding-top: 100px;'"
    >
      <router-view></router-view>
    </div>
    <footer class="footer">
      <div class="footer-top flex">
        <div>
          <div class="footer-Logo">
            <img src="@/assets/img/footer_Logo.png" alt="" />
          </div>
          <div class="flex">
            <div
              class="footerList flex"
              v-for="(item, index) in footerList"
              :key="index"
            >
              <div v-show="index !== 0" class="footerList-Grid">|</div>
              <div>{{ item.title }}</div>
            </div>
          </div>
        </div>
        <div class="footer-right flex">
          <div>
            <div class="footer-Accounts">
              <img
                src="http://bqss-oss.oss-cn-hangzhou.aliyuncs.com/ipjy/IPJY/gzh_img.jpg"
                alt=""
              />
            </div>
            <div style="margin-top: 10px">官方公众号</div>
          </div>
          <!-- <div>
            <div class="footer-Accounts">
              <img
                src="http://bqss-oss.oss-cn-hangzhou.aliyuncs.com/ipjy/IPJY/PC_img/ipjy_article.jpg"
                alt=""
              />
            </div>
            <div style="margin-top: 10px">资讯号二维码</div>
          </div> -->
          <div>
            <div class="footer-Applets">
              <img
                src="http://bqss-oss.oss-cn-hangzhou.aliyuncs.com/ipjy/IPJY/xcx_img.jpg"
                alt=""
              />
            </div>
            <div style="margin-top: 10px">小程序二维码</div>
          </div>
        </div>
      </div>
      <div class="footer-bottom center-noflex font-first">
        <!-- <div>COPYRIGHT R2020 - 2024 江苏省文化产权交易所版权所有</div> -->
        <div class="font-a-link">
          <a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            style="color: #ffffff"
            target="_blank"
            >苏ICP备11082521号-1</a
          >
          <a href="###" style="color: #ffffff"
            ><span style="margin-left: 10px"></span>苏B1.B2-20140186</a
          >
          <a
            href="http://sq.ccm.gov.cn:80/ccnt/sczr/service/business/emark/toDetail/45CC7693341E40A19C2735C788F07960"
            target="_blank"
            style="color: #ffffff"
          >
            <!-- <span style="margin-left: 10px"></span>
            <img src="http://bqss-oss.oss-cn-hangzhou.aliyuncs.com/tymh/ipjy.png"
              style="width: 24px; high: 24px"/> -->
            </a>
        </div>
        <!-- <div>苏ICP备11082521号-1</div>苏网文[2013]0384-018号
          http://bqss-oss.oss-cn-hangzhou.aliyuncs.com/ipjy/IPJY/ipjy.png
				<div><im g style="width:50px;height:50px;" src="https://bqss-oss.oss-cn-hangzhou.aliyuncs.com/ipjy/IPJY/ipjy.png" alt="" />苏网文[2013]0384-018号</div>
			-->
      </div>
    </footer>
    <el-dialog
      :visible.sync="loginVisible"
      width="24%"
      :close-on-click-modal="false"
    >
      <login @ForgotPass="ForgotPass" @Setlogin="Setlogin"></login>
    </el-dialog>
    <el-dialog
      :visible.sync="ForgotVisible"
      width="24%"
      :close-on-click-modal="false"
    >
      <forgot @updatepass="updatapass"></forgot>
    </el-dialog>
  </div>
</template>

<script>
import login from "components/Login.vue";
import forgot from "components/Forgot.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "index_home",
  components: {
    login,
    forgot,
  },
  data() {
    return {
      loginVisible: false,
      ForgotVisible: false,
      footerList: [
        {
          title: "关于我们",
        },
        {
          title: "免责声明",
        },
        {
          title: "交易流程",
        },
        {
          title: "交易规则",
        },
        {
          title: "联系我们",
        },
      ],
      uinfo: {
        token: "",
        nickname: "",
        phone: "",
        userinfoL: [],
      },
    };
  },

  computed: {
    ...mapGetters(["userinfo"]),
  },
  methods: {
    ...mapActions(["Setuserinfo"]),
    ForgotPass() {
      this.loginVisible = false;
      this.ForgotVisible = true;
    },
    Setlogin(e) {
      this.loginVisible = e;
      this.ForgotVisible = e;
    },
    updatapass() {
      this.loginVisible = true;
      this.ForgotVisible = false;
      this.Setuserinfo({
        token: "",
        nickname: "",
        phone: "",
      });
    },
    async userDetail(e) {
      //console.log(e);
      var id = this.userinfo.userinfo[e].id;
      var token = this.userinfo.token;
      const { data } = await this.$api({
        c: "user",
        a: "qh_name",
        user_id: id,
        token: token,
      });
      //console.log(data);
      if (data) {
        this.userinfo.nickname = data.nickname;
        this.Setuserinfo(data);
      } else {
        this.$message.error(data.description);
      }
    },
    loginOut() {
      var data = this.uinfo;
      this.Setuserinfo(data);
      var code=localStorage.getItem('sign');
      localStorage.removeItem("sign");
      localStorage.removeItem("uid");
      localStorage.clear();
      this.outsso(code);
      
    },
    outsso(code){
      var self=this;
      // let param = new URLSearchParams();
      //     param.append('sign',code);
      //      param.append('c','sso_login');
      //       param.append('a','out_login');
         self.$axios({
                method:"get",
                url:"https://www.jscaee.com.cn/rest/index.php?c=sso_login&a=out_login&sign="+code,
            }).then((res) => {
             console.log(res.data.data);
              var  elem_tymh = document.createElement("iframe");
                  elem_tymh.width = 0;
                  elem_tymh.height = 0;
                  elem_tymh.src = "https://www.jscaee.com.cn/portal/#/";
                  document.body.appendChild(elem_tymh);
                  elem_tymh.onload = elem_tymh.onreadystatechange = function () {
                    if (
                      !this.readyState ||
                      this.readyState === "loaded" ||
                      this.readyState === "complete"
                    ) {
                      // if (callback) {
                      //   // callback();
                      // }
                    }
                  };

                var elem_sybm = document.createElement("iframe");
                    elem_sybm.width = 0;
                    elem_sybm.height = 0;
                    elem_sybm.src = "https://bqss.jscaee.com.cn/portal/#/";
                    document.body.appendChild(elem_sybm);
                    elem_sybm.onload = elem_sybm.onreadystatechange = function () {
                      if (
                        !this.readyState ||
                        this.readyState === "loaded" ||
                        this.readyState === "complete"
                      ) {
                        // if (callback) {
                        //   // callback();
                        // }
                      }
                    };

                     var elem_ipjy = document.createElement("iframe");
                    elem_ipjy.width = 0;
                    elem_ipjy.height = 0;
                    elem_ipjy.src = "https://ip.jscaee.com.cn/portal/#/index/home";
                    document.body.appendChild(elem_ipjy);
                    elem_ipjy.onload = elem_ipjy.onreadystatechange = function () {
                      if (
                        !this.readyState ||
                        this.readyState === "loaded" ||
                        this.readyState === "complete"
                      ) {
                        // if (callback) {
                        //   // callback();
                        // }
                      }
                    };

                     var elem_ysjy = document.createElement("iframe");
                      elem_ysjy.width = 0;
                      elem_ysjy.height = 0;
                      elem_ysjy.src = "https://film.jscaee.com.cn/portal/#/";
                      document.body.appendChild(elem_ysjy);
                      elem_ysjy.onload = elem_ysjy.onreadystatechange = function () {
                        if (
                          !this.readyState ||
                          this.readyState === "loaded" ||
                          this.readyState === "complete"
                        ) {
                          // if (callback) {
                          //   // callback();
                          // }
                        }
                      };

          });
   
      
     
    }


  },
};
</script>

<style lang="scss" scoped>
.index {
  display: flex;
  flex-direction: column;
}

.header-fixed {
  position: absolute;

  top: 0;
  width: 100%;
  background: transparent !important;
}

.header {
  padding: 10px 0;
  z-index: 999;
  background: rgb(0, 118, 254);
  justify-content: space-around;
  background-size: 100%;
  padding-top: 18px;
  // img {
  //   width: 100px;
  //   height: 25px;
  // }
}

.footer {
  color: #ffffff;
  background: rgb(10, 22, 51);

  .footer-bottom {
    padding: 5px 0 5px;
  }
  .footer-Logo {
    margin-bottom: 30px;

    img {
      transform: translateX(-14%) scale(0.7);
    }
  }
}

.footer-top {
  justify-content: space-around;
  padding: 60px 0;
  background: url("http://bqss-oss.oss-cn-hangzhou.aliyuncs.com/ipjy/IPJY/PC_img/foot_14.png")
    no-repeat;
  background-size: cover;
  border-bottom: 1px solid #7a8ba6;
}

.footerList {
  color: #ffffff;
  font-size: 12px;

  .footerList-Grid {
    margin: 0 10px;
  }
}

.footer-Accounts {
  margin-top: 25px;
  margin-right: 18px;

  img {
    width: 70px;
    height: 70px;
  }
}

.footer-Applets {
  img {
    width: 70px;
    height: 70px;
  }

  margin-top: 25px;
}

.menu-tab {
  a {
    display: flex;
    align-items: center;
    margin: 0 20px;
    border-bottom: 2px solid transparent;

    li {
      padding-bottom: 5px;
      color: #ffffff;
    }
  }
}

.router-link-active {
  border-bottom: 2px solid #ffffff !important;
}

.footer-right {
  font-size: 12px;
}

.header-fixed-aquites {
  position: absolute;
  width: 100%;
  height: 100px;
}

.header-img {
  position: absolute;
  left: 11%;
  top: 65%;
}
.header-img-aquites {
  position: absolute;
  left: 11%;
  top: 30%;
}
.header-UL {
  position: absolute;
  right: 120px;
  top: 65%;
}
.header-UL-aquites {
  position: absolute;
  right: 120px;
  top: 30%;
}

.font-a-link a {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin: auto 0;
}

.font-a-link img {
  vertical-align: middle;
}
</style>
