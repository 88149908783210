import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'normalize.css/normalize.css';
// ElementUI.Dialog.props.lockScroll.default = true;
Vue.use(ElementUI);
Vue.config.productionTip = false
import api from "../api/index";

Vue.prototype.$api = api

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
