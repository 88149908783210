import axios from "axios"
import vue from "vue"

vue.prototype.$axios = axios

const server = axios.create({
    baseURL: "https://ip.jscaee.com.cn/rest/index.php",
	// baseURL: "/base"baseURL: "https://ip.jscaee.com.cn/rest/index.php",
    // 上生产时换服务器接口地址
})
export default server