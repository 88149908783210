<template>
  <div class="Forgot">
    <!-- <div class="Forgot_title">忘记密码</div>
    <div class="Forgot_Text">FORGET THE PASSWORD</div> -->
    <div style="transform: scale(0.7); position: relative; left: -40px">
      <img src="@/assets/login/foemite.png" alt="" />
    </div>
    <el-form ref="forms" :model="forms" :rules="rules">
      <el-form-item prop="tel">
        <el-input v-model="forms.tel" placeholder="请输入手机号">
          <i slot="prefix">
            <img
              style="position: relative; top: 2px; width: 18px; height: 18px"
              src="@/assets/user/user_icon1.png"
              alt=""
            />
          </i>
        </el-input>
      </el-form-item>
      <el-form-item prop="code">
        <div class="flex">
          <el-input v-model="forms.code" placeholder="请输入验证码">
            <i slot="prefix">
              <img
                style="position: relative; top: 5px; width: 18px; height: 18px"
                src="@/assets/user/user_icon3.png"
                alt=""
              />
            </i>
          </el-input>
          <div class="codeSelect" @click="GetQRCode">
            {{ codeDX.type ? codeDX.message : codeDX.message + "秒后重新发送" }}
          </div>
        </div>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="forms.password"
          placeholder="请输入新密码"
          type="password"
        >
          <i slot="prefix">
            <img
              style="position: relative; top: 5px; width: 18px; height: 18px"
              src="@/assets/user/user_icon3.png"
              alt=""
            />
          </i>
        </el-input>
      </el-form-item>
      <el-form-item prop="passwords">
        <el-input
          v-model="forms.passwords"
          placeholder="请重复输入您的新密码"
          type="password"
        >
          <i slot="prefix">
            <img
              style="position: relative; top: 5px; width: 18px; height: 18px"
              src="@/assets/user/user_icon3.png"
              alt=""
            />
          </i>
        </el-input>
      </el-form-item>
      <el-form-item>
        <div class="send" @click="ForgotSend">提交</div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "Forgot",
  data() {
    let checkPhones = (rule, value, callback) => {
      if (value) {
        const reg = /^1[1|3|4|5|7|8|9][0-9]\d{8}$/;

        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value !== this.forms.password) {
        callback(new Error("两次输入密码不一致!"));
      }
    };

    return {
      rules: {
        tel: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: checkPhones, trigger: "blur" },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            min: 6,
            message: "密码最少6位字符",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
        passwords: [
          {
            required: true,
            message: "请再次输入密码",
            trigger: "blur",
          },
          {
            validator: validatePass2,
            trigger: "blur",
          },
        ],
      },
      forms: {
        tel: "",
        password: "",
        code: "",
        passwords: "",
      },
      codeDX: {
        type: true,
        message: "获取验证码",
      },
      codeDXOBJ: null,
    };
  },
  methods: {
    ForgotSend() {
      this.$refs.forms.validate(async (data) => {
        if (data) {
          const data = await this.$api({
            c: "login",
            a: "pc_uppass",
            user_phone: this.forms.tel,
            password: this.forms.passwords,
            smscode: this.forms.code,
          });
          if (data.errorCode == 200) {
            this.$message({
              type: "success",
              message: "密码修改成功，请重新登录",
            });
            this.$emit("updatepass")
          }
        }
      });
    },
    A() {
      //console.log(this.Forgot);
    },
    GetQRCode() {
      if (!this.codeDX.type) {
        return false;
      }
      this.$refs.forms.validateField("tel", async (data) => {
        //console.log(data);
        if (!data) {
          const data = await this.$api({
            c: "smscode",
            a: "smssend",
            phone: this.forms.tel,
          });
          //console.log(data);
          if (data.errorCode == 200) {
            this.$message({
              type: "success",
              message: "短信发送成功，请注意查收",
            });

            this.codeDX = {
              type: false,
              message: 60,
            };
            this.codeDXOBJ = setInterval(() => {
              this.codeDX.message--;
              if (this.codeDX.message <= 0) {
                this.codeDX = {
                  message: "获取验证码",
                  type: true,
                };
                clearInterval(this.codeDXOBJ);
              }
            }, 1000);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.Forgot {
  padding: 0px 0px 10px;
  .Forgot_title {
    color: rgb(55, 69, 103);
    font-size: 20px;
  }
  .Forgot_Text {
    margin-top: 4px;
    color: rgb(122, 139, 166);
    font-style: italic;
    font-size: 12px;
    margin-bottom: 20px;
  }
  .end {
    font-size: 12px;
    color: #565656;
  }
  .send {
    height: 40px;
    padding: 4px 0;
    width: 100%;
    border-radius: 8px;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    background: rgb(0, 118, 254);
  }
}
.codeSelect {
  margin-left: 10px;
  border-radius: 4px;
  font-size: 12px;
  padding: 0px 15px;
  background-color: rgb(0, 118, 254);
  color: #ffffff;
}
</style>