import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userinfo: {
      token: "",
      nickname: "",
      phone: ""
    }
  },
  mutations: {
    inuserinfo(state, e) {
      state.userinfo = e
    }
  },
  actions: {
    Setuserinfo({ commit }, data) {
      commit("inuserinfo", data)
    }
  },
  getters: {
    userinfo: state => state.userinfo
  },
  plugins: [createPersistedState({
    storage: window.localStorage,
    reducer(val) {
      return {
        userinfo: val.userinfo
      }
    }
  })]
})
