<template>
  <div id="app">
    <router-view class="view"></router-view>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  async created() {
    var url = window.location.href;
    // console.log("------------",url);
    const str = url.substr(url.indexOf("?") + 1, url.length);
    if(str.indexOf("&") != -1){
      const params = str.split("&");
      const code = params[0].split("=")[1];
      if(!code || code=='undefined'){
        //console.log(222);
        this.calose_out();
        return false;
      }
      const uid = params[1].split("=")[1];
      this.$axios.get('https://www.jscaee.com.cn/rest/index.php?c=sso_login&a=exit_login&sign='+code+'&uid='+uid)
          .then((res) => {
              //console.log('kaishi',res.data.data);
              if(res.data.errorCode==200){
                  localStorage.setItem("sign",code);
                  localStorage.setItem("uid",uid);
                this.login_login(code,uid);
              }else{
                this.calose_out();
              }
          })
    }else{
       var code=localStorage.getItem("sign");
       var uid=localStorage.getItem("uid");
     
        this.$axios.get('https://www.jscaee.com.cn/rest/index.php?c=sso_login&a=exit_login&sign='+code+'&uid='+uid)
              .then((res) => {
                  //console.log('结束',res.data.data);
                  if(res.data.errorCode==200){
                    this.login_login(code,uid);
                  }else{
                    this.calose_out();
                  }
              })
          }
  },
  methods: {
        ...mapActions(["Setuserinfo"]),
        async login_login(code,uid){
          const {
            data
          } = await this.$api({
            c: "login",
            a: "sso_login",
            sign: code,
            uid: uid,
          });
          //console.log(data);
          this.Setuserinfo(data);
          this.$emit("Setlogin",false);
        },
      calose_out(){
        localStorage.removeItem("sign");
        localStorage.removeItem("uid")
        this.Setuserinfo('');
        this.$emit("Setlogin",true);
      }
    }
};
</script>
<style lang="scss">
html,
body,
#app {
  // html body 自动撑满
  width: 100%;
  height: 100%;
  font-size: 14px;
  white-space: nowrap;
  margin: 0 auto;
}
#app {
  display: flex;
  flex-direction: column;
  .view {
    flex-grow: 1;
  }
}

body {
  padding-right: 0px !important;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #f5f5f5;
    display: none;
  }
  /*定义滚动条轨道
 内阴影+圆角*/
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    display: none;
  }
  /*定义滑块
 内阴影+圆角*/
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgb(221, 222, 224);
    display: none;
  }
}
* {
  // 清空默认全局样式
  margin: 0;
  padding: 0;
  box-sizing: border-box; // 标准盒子模型 不会请百度
  text-decoration: none; //清空a标签默认的下划线
  list-style: none; // 清空li 前面的点
  outline: none; // 清空按钮和 input 获取焦点后的样式
  background-color: transparent; // 默认标准背景颜色
  border: none;
  user-select: none;
  touch-action: none;
}
a {
  // a标签默认的自带颜色清空
  color: #212121;
}
input {
  -webkit-user-select: auto; /*webkit浏览器*/
}
textarea {
  -webkit-user-select: auto; /*webkit浏览器*/
}
.font-first {
  font-size: 12px; /* no */
}
.font-two {
  font-size: 14px; /* no */
}
.font-three {
  font-size: 16px; /* no */
}
.font-four {
  font-size: 18px; /* no */
}
.font-fiver {
  font-size: 24px; /* no */
}
.flex {
  display: flex;
}
.align-center {
  display: flex;
  align-items: center;
}
.align-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.align-top {
  display: flex;
  align-items: flex-start;
}
.align-end {
  display: flex;
  align-items: flex-end;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.center-noflex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.grow-1 {
  flex-grow: 1;
}
.grow-2 {
  flex-grow: 2;
}
p {
  white-space: normal;
}
</style>
